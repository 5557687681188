import { render, staticRenderFns } from "./videoCall.vue?vue&type=template&id=2986fffa&scoped=true&"
import script from "./videoCall.vue?vue&type=script&lang=js&"
export * from "./videoCall.vue?vue&type=script&lang=js&"
import style0 from "./videoCall.vue?vue&type=style&index=0&id=2986fffa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2986fffa",
  null
  
)

export default component.exports